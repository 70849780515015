import React from "react"
import SEO from "../components/seo"
import { graphql, Link } from "gatsby"
import Triangle from "../components/triangle/triangle"

const slugify = require("slugify")

const DigitalServicesLandingPage = ({ data: { services, page }, location }) => {
  return (
    <>
      <SEO
        titleOverride={page.metaTags && page.metaTags.title ? page.metaTags.title : page.title}
        descriptionOverride={page.metaTags && page.metaTags.description ? page.metaTags.description : null}
        pathnameOverride={location.pathname}
        imageOverride={page.metaTags && page.metaTags.image ? page.metaTags.image.url : null}
      />

      <section className="pt-16 pb-16 mb-16 text-white lg:mb-24 bg-secondary">
        <div className="container">
          <div className="w-full mx-auto 2xl:w-10/12">
            <div className="w-full mx-auto text-center md:w-9/12 lg:w-8/12">
              <h1>{ page.heroHeading }</h1>
              <div className="content content--reset md:w-10/12 md:mx-auto" dangerouslySetInnerHTML={{ __html: page.heroExcerpt }}></div>

              <Link className="capitalize btn btn--primary" to="/work/">View our work</Link>
            </div>
          </div>
        </div>
      </section>

      <section className="mb-12 overflow-hidden md:mb-16">
        <div className="container">
          <div className="w-full mx-auto md:w-10/12">
            <div className="flex flex-wrap md:-mx-12">
              {services.edges.map(({ node }, index) => {
                let slugged = slugify(node.model.apiKey + 's',{
                  lower: true,
                }).replace(/[_]/g, '-');
                return(
                  <div key={index} className="w-full pb-6 mb-8 text-center sm:text-left sm:w-1/2 md:w-1/3 lg:w-1/4 md:px-12 md:mb-16 md:pb-0">

                    { node.icon && (
                      // @TODO convert this to SVG component?
                      <svg width="35" height="35" className="block mx-auto mb-8 lg:mb-10 sm:mx-0">
                        <image href={node.icon.url} src={node.icon.url} width="35" height="35"/>
                      </svg>
                    )}

                    <Link to={`/${slugged}/${node.slug}/`} className="block mb-6 text-2xl transition duration-500 ease-in-out h3 lg:text-2xl xl:text-3xl text-secondary hover:text-secondary-dark focus:text-secondary-dark">{ node.title }</Link>

                    <nav aria-labelledby="webdesignmenu">
                      <span id="webdesignmenu" className="sr-only">{ node.title } Menu</span>
                      <ul className="block">
                        <li className="block" key={index}>
                          <Link to={`/${slugged}/${node.slug}/`} className="block mb-2 text-lg link hover:text-primary focus:text-primary"><span className={`mr-2 inline-block text-primary`}><Triangle size={`small`} /></span> { node.title }</Link>
                        </li>
                        {node.treeChildren.length > 0 && (
                          <>
                            {node.treeChildren.map(({ title, slug, treeParent, ppcOnlyPage }, index) =>
                            <>
                              {!ppcOnlyPage && (
                                <li className="block" key={index}>
                                  <Link to={`/${slugged}/${treeParent.slug}/${slug}/`} className="block mb-2 text-lg link hover:text-primary focus:text-primary"><span className={`mr-2 inline-block text-primary`}><Triangle size={`small`} /></span> { title }</Link>
                                </li>
                              )}
                              </>
                            )}

                            {node.title === "Web Design" && (
                              <li><Link to="/services/web-design/ecommerce-agency/woocommerce-agency" className="block mb-2 text-lg link hover:text-primary focus:text-primary"><span className={`mr-2 inline-block text-primary`}><Triangle size={`small`} /></span> WooCommerce Agency</Link></li>
                            )}
                          </>
                        )}
                      </ul>
                    </nav>
                  </div>
                )
              })}

              <div className="hidden w-full mb-8 border lg:block lg:flex-1 md:mb-16 border-secondary text-secondary">
                <div className="flex flex-wrap items-center h-full p-10">
                  <div className="w-full">
                    <span className="block mb-6 text-xl">Our digital services help businesses generate leads.</span>
                  </div>
                  <div className="w-full">
                    <Link to="/work/" className="pb-0 mb-0 text-xl text-secondary font-display">See our work <span className="inline-block ml-2 text-primary"><Triangle size={`small`} /></span></Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="mb-16 lg:mb-24">
        <p className="px-12 mb-8 text-2xl leading-tight text-center align-middle lg:text-3xl font-display md:px-24">Get in touch today on <a href="tel:01159598900" className="inline-block break-all transition duration-500 ease-in-out text-primary hover:text-primary-dark focus:text-primary-dark contactlink">0115 959 8900</a> or <span className="ml-2 align-middle"><Link to="/contact/" className="text-base btn btn--outline">Get in touch</Link></span></p>
      </section>
    </>
  )
}

export default DigitalServicesLandingPage

export const query = graphql`
  query DigitalServicesLandingPageQuery {
    services: allDatoCmsService(filter: { root: { eq: true } }, sort: { fields: [position], order: ASC }) {
      edges {
        node {
          title
          slug
          model {
            apiKey
          }
          icon {
            url
          }
          treeChildren {
            title
            slug
            ppcOnlyPage
            treeParent {
              slug
            }
          }
        }
      }
    }

    page: datoCmsDigitalServicesLanding {
      metaTags {
        title
        description
        twitterCard
        image {
          url
        }
      }
      title
      heroHeading
      heroExcerpt
      slug
    }
  }
`
